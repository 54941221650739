import "../Components/SlidableMenu.scss";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";

const SlidableMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUniversMenuOpen, setIsUniversMenuOpen] = useState(false);
  const location = useLocation(); // Obtenir l'emplacement actuel
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Vérifier si la route actuelle correspond à la page d'accueil
  const isWhitePage =
    location.pathname === "/" ||
    location.pathname === "/adn" ||
    location.pathname === "/contact";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsUniversMenuOpen(false);
  };

  const toggleSubUniversMenu = () => {
    setIsUniversMenuOpen(!isUniversMenuOpen);
  };

  return (
    <div className={`menu-container ${isWhitePage ? "white" : ""}`}>
      <div onClick={toggleMenu} className="menu-button">
        <div className={`hoverCursor burger-icon ${isOpen ? "open" : ""}`}>
          <div className={`bar bar1 ${isWhitePage ? "white" : ""}`}></div>
          <div className={`bar bar2 ${isWhitePage ? "white" : ""}`}></div>
          <div className={`bar bar3 ${isWhitePage ? "white" : ""}`}></div>
        </div>
        <div className="menuName">{!isMobile && "MENU"}</div>
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <ul className="hoverCursor mainMenu">
        <li
            onClick={toggleMenu}
            className={`menuLink ${isWhitePage ? "white" : ""}`}
          >
            <Link
              onClick={toggleMenu}
              className={`menuLink ${isWhitePage ? "white" : ""}`}
              to="/nosrealisations-meubles-en-bois-sur-mesure"
            >
              NOS REALISATIONS
            </Link>
          </li>
          <li
            className={`hoverCursor menuLink ${isWhitePage ? "white" : ""}`}
            onClick={toggleSubUniversMenu}
          >
            UNIVERS
          </li>
          {isUniversMenuOpen && (
            <ul className="subMenu">
              <li className="menuLink">
                <Link
                  onClick={toggleMenu}
                  className={`subMenuLink ${isWhitePage ? "white" : ""}`}
                  to="/univers#campagne"
                >
                  CAMPAGNE
                </Link>
              </li>
              <li className="menuLink">
                <Link
                  onClick={toggleMenu}
                  className={`subMenuLink ${isWhitePage ? "white" : ""}`}
                  to="/univers#contemporain"
                >
                  CONTEMPORAIN
                </Link>
              </li>
              <li className="menuLink">
                <Link
                  onClick={toggleMenu}
                  className={`subMenuLink ${isWhitePage ? "white" : ""}`}
                  to="/univers#manoir"
                >
                  MANOIR
                </Link>
              </li>
              <li
                className={`subMenuLink proLink ${isWhitePage ? "white" : ""}`}
              >
                <Link
                  onClick={toggleMenu}
                  className={`subMenuLink ${isWhitePage ? "white" : ""}`}
                  to="/univers#professionnel"
                >
                  PROFESSIONNEL
                </Link>
              </li>
            </ul>
          )}
          <li
            onClick={toggleMenu}
            className={`menuLink ${isWhitePage ? "white" : ""}`}
          >
            <Link
              onClick={toggleMenu}
              className={`menuLink ${isWhitePage ? "white" : ""}`}
              to="/creezVotreUnivers-meubles-en-bois-fait-main"
            >
              CREEZ VOTRE UNIVERS
            </Link>
          </li>
          <li
            onClick={toggleMenu}
            className={`menuLink ${isWhitePage ? "white" : ""}`}
          >
            <Link
              onClick={toggleMenu}
              className={`menuLink ${isWhitePage ? "white" : ""}`}
              to="/condensederecherche"
            >
              CONDENSÉ DE RECHERCHES
            </Link>
          </li>
          <li className={`menuLink ${isWhitePage ? "white" : ""}`}>
            <Link
              onClick={toggleMenu}
              className={`menuLink ${isWhitePage ? "white" : ""}`}
              to="/#adn"
            >
              ADN & VALEURS
            </Link>
          </li>
          <li className={`menuLink ${isWhitePage ? "white" : ""}`}>
            <Link
              onClick={toggleMenu}
              className={`menuLink ${isWhitePage ? "white" : ""}`}
              to="/contact"
            >
              PRENDRE RENDEZ-VOUS
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SlidableMenu;
