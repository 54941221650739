import React from "react";
import Carousel from "./Carousel";

const CondenseItem = ({ condenseItemName, photos, mainPhoto }) => {
  const backgroundStyle = mainPhoto
    ? {
        backgroundImage: `url(${mainPhoto.fileName})`,
        backgroundSize: "cover" /* Adjusts the background image size */,
        backgroundPosition: "center" /* Centers the background image */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }
    : {};

  return (
    <>
      <div className="typeRechercheContainer">
        <div className="titleCondenseRecherches" style={backgroundStyle}>
          {condenseItemName}
        </div>
        <div className="photosContainer">
          <Carousel photos={photos} />
        </div>
      </div>
    </>
  );
};

export default CondenseItem;
