import { React } from "react";
import logoWhite from ".././img/logo-maison-charlet-blanc.webp";
import logoBlack from ".././img/logo-maison-charlet-noir.webp";
import "./Header.scss";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation(); // Obtenir l'emplacement actuel

  // Vérifier si la route actuelle correspond à la page d'accueil
  const isHomePage = location.pathname === "/";
  const isContactPage = location.pathname === "/contact";

  const isBlackPage =
    location.pathname.startsWith("/univers") ||
    location.pathname.startsWith("/condensederecherche") ||
    location.pathname.startsWith("/creezVotreUnivers-meubles-en-bois-fait-main") ||
    location.pathname.startsWith("/nosrealisations-meubles-en-bois-sur-mesure");

  const instaImage = `/img/instaImage.webp`;
  const instaImageNoir = `/img/instaImageNoir.webp`;

  return (
    <div className={isBlackPage ? "header blurred" : "header"}>
      <div className="headerContainer">
        {
          <>
            {!isHomePage && (
              <>
                <Link to="/">
                  <img
                    src={isContactPage ? logoWhite : logoBlack}
                    className="logoHeader"
                    alt="Logo Maison Charlet, conception de meubles sur mesure"
                  />
                </Link>
              </>
            )}
            <a
              href="https://www.instagram.com/maison_charlet/"
              className="instaLogo"
              style={{
                backgroundImage: `url(${
                  isBlackPage ? instaImageNoir : instaImage
                })`,
              }}
              aria-label="Découvrez l'Instagram de Maison Charlet"
            ></a>
          </>
        }
      </div>
    </div>
  );
};

export default Header;
