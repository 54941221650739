// Questionnaire.jsx

import React, { useState } from "react";
import contact from ".././img/contact.webp";
import "./Questionnaire.scss"; // Import du fichier CSS
import Footer from "./Footer";
import { createContact, updateContact } from "../Services/Database";

function Questionnaire({ questions }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isValidated, setIsValidated] = useState(true);
  const [answerValue, setAnswerValue] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState();
  let isValidatedOnce = false;

  const sendAnswerToServer = async (answer, receiveEmail) => {
    if (currentQuestionIndex === 0) {
      let records = await createContact(answer);
      records.data.forEach(function (record) {
        setCurrentQuestionId(record.id);
      });
    } else {
      if (currentQuestionIndex === 2) {
        updateContact(currentQuestionId, "RelanceEmail", receiveEmail);
      }

      // Mise à jour de l'enregistrement trouvé
      const fieldName = questions[currentQuestionIndex].dataName;
      updateContact(currentQuestionId, fieldName, answer);
    }
  };

  const handleValidation = () => {
    isValidatedOnce = true;
    const answer = document.getElementById("answer").value;
    if (validateAnswer(answer, questions[currentQuestionIndex].type)) {
      setIsValidated(true);
      isValidatedOnce = false;
      sendAnswerToServer(answer, receiveEmail);
      if (currentQuestionIndex === questions.length - 1) {
        updateContact(currentQuestionId, "Valide", true);
        setSubmitted(true);
      } else {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
      setAnswerValue("");
    } else {
      setIsValidated(false);
    }
  };

  const handleEmailCheckboxChange = () => {
    setReceiveEmail(!receiveEmail);
  };

  const handleInputChange = (e) => {
    setAnswerValue(e.target.value);
    const valid = validateAnswer(
      e.target.value,
      questions[currentQuestionIndex].type
    );
    if (isValidatedOnce) {
      setIsValidated(valid);
    }
  };

  const validateAnswer = (answer, questionType) => {
    switch (questionType) {
      case "text":
        return answer.trim() !== ""; // Validation basique pour un champ texte non vide
      case "email":
        // Validation d'une adresse email simple
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(answer);
      case "phone":
        // Validation d'un numéro de téléphone : uniquement des chiffres et longueur de 10 caractères
        const phonePattern = /^[0-9]{10}$/;
        return phonePattern.test(answer);
      case "date":
        // Validation d'une date simple (format YYYY-MM-DD)
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        return datePattern.test(answer);
      default:
        return true; // Si le type de question n'est pas reconnu, considérer la réponse comme valide
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${contact})`,
    backgroundSize: "cover" /* Adjusts the background image size */,
    backgroundPosition: "center" /* Centers the background image */,
  };

  return (
    <div className="backgroundQuestionnaire" style={backgroundStyle}>
      <div className="questionnaireContainer">
        {!submitted ? (
          <>
            <div className="questionTitle">
              {questions[currentQuestionIndex].text}
            </div>
            <div className="questionLeft">
              {currentQuestionIndex + 1} / {questions.length}
              </div>
            <div
              className={
                isValidated ? "inputWrapper" : "inputWrapper isNotValidated"
              }
            >
              <input
                type="text"
                id="answer"
                value={answerValue}
                className="answerInputQuestionnaire defaultCursor"
                onChange={handleInputChange}
              />
              <button
                onClick={handleValidation}
                className="submitButtonQuestionnaire"
                aria-label="Envoyer le formulaire"
              >
                Envoyer
              </button>
            </div>
            <br />
            {currentQuestionIndex === 2 ? (
              <div className="emailCheckboxContainer">
                <label htmlFor="emailCheckbox" className="emailLabel">
                  Recevoir les informations par email
                  <input
                    type="checkbox"
                    id="emailCheckbox"
                    className="emailCheckbox"
                    checked={receiveEmail}
                    onChange={handleEmailCheckboxChange}
                  />
                </label>
              </div>
            ) : (
              ""
            )}
            <br />
          </>
        ) : (
          <div>
            <h3 className="thankYouMessage">
              Merci pour vos réponses, nous vous recontacterons très prochainement
            </h3>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}

export default Questionnaire;
