import React, { useState } from "react";
import "./DropDownCard.scss";

const DropDownCard = ({ photo, texte, title, altText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const styleChevron = {
    backgroundImage: 'url("/img/chevron.webp")',
  };

  return (
    <>
      <div className="dropDownCardContainer">
        <h1 className="dropdrownTitle">{title}</h1>
        <img className="dropDownImage" src={photo} alt={altText}></img>
        <div className="dropContentContainer">
          <div className="dropCenterContainer">
            <div className="dropdown-button-container">
              <button
                className={
                  isOpen ? "dropdown-button rotate" : "dropdown-button"
                }
                style={styleChevron}
                onClick={toggleDropdown}
                aria-label="Ouvrir le menu vers le carousel"
              />
            </div>
          </div>
          <div className={`dropdown-container  ${isOpen ? "open" : ""}`}>
            <div className={`dropdown-content`}>{texte}</div> 
          </div>
        </div>
      </div>
    </>
  );
};

export default DropDownCard;
