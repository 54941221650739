import React, { useEffect } from "react";
import "./CookieConsentBanner.css";

const CookieConsent = () => {
  useEffect(() => {
    const storedConsent = localStorage.getItem("consentMode");
    if (storedConsent) {
      hideBanner();
    }
  }, []);

  const hideBanner = () => {
    document.getElementById("cookie-consent-banner").style.display = "none";
  };

  const handleAcceptAll = () => {
    const newConsent = {
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
      userData: true,
      personalization: true
    };
    updateConsent(newConsent);
    hideBanner();
  };

  const handleRejectAll = () => {
    const newConsent = {
      necessary: false,
      analytics: false,
      preferences: false,
      marketing: false,
      userData: false,
      personalization: false
    };
    updateConsent(newConsent);
    hideBanner();
  };

  const updateConsent = (newConsent) => {
    const consentMode = {
      functionality_storage: newConsent.necessary ? "granted" : "denied",
      security_storage: newConsent.necessary ? "granted" : "denied",
      ad_storage: newConsent.marketing ? "granted" : "denied",
      analytics_storage: newConsent.analytics ? "granted" : "denied",
      personalization_storage: newConsent.preferences ? "granted" : "denied",
      ad_user_data: newConsent.userData ? "granted" : "denied",
      ad_personalization: newConsent.personalization ? "granted" : "denied",
    };
    window.gtag("consent", "update", consentMode);
    localStorage.setItem("consentMode", JSON.stringify(consentMode));
  };

  return (
    <div id="cookie-consent-banner" className="cookie-consent-banner">
      <div id="cookie-consent-container">
        <p>
          En cliquant sur « Accepter tous les cookies », vous acceptez
          l’utilisation de cookies essentiels au fonctionnement du site, à des
          fins de personnalisation, de statistiques et de publicités ciblées,
          incluant des cookies de tiers.
        </p>
        <div>
          <button
            id="btn-reject-all"
            className="cookie-consent-button btn-grayscale"
            onClick={handleRejectAll}
          >
            Refuser les cookies
          </button>
          <button
            id="btn-accept-all"
            className="cookie-consent-button btn-success"
            onClick={handleAcceptAll}
          >
            Accepter les cookies
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
