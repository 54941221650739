import React from "react";
import "./Promesses.scss";
import DropDownCard from "./DropDownCard";

const Promesses = () => {
  const photo1 =
    "/img/promesses/Ponçage_bois_massif_pieddetable_fabrication_MaisonCharlet.webp";
  const texte1 =
    "Notre approche vise à soutenir l'économie locale tout en contribuant à la réduction de notre empreinte carbone. Engagés dans une démarche de responsabilité sociale et environnementale, nous sommes fiers d'être labellisés EcoVadis, ce qui témoigne de notre engagement en faveur de pratiques durables et éthiques tout au long de notre processus de fabrication.";
    const title1 = "ETHIQUE";
  const alt1 =
    "Ponçage d’un pied de table en bois de forme arrondie. Création d’une table en chêne massif sur-mesure. Fabrication haut de gamme éternel sur-mesure et éthique Maison Charlet.";
  const photo2 =
    "/img/promesses/sur-mesure_fabrication_tracé_mesure_bois_Maisoncharlet.webp";
  const texte2 =
    "L'intégralité des meubles Maison Charlet est pensée, dessinée et réalisée à la main et sur-mesure, garantissant des pièces uniques. Dans notre approche de fabrication, nous privilégions les circuits courts, ce qui explique notre choix de travailler principalement le chêne, un bois noble et durable issu de forêts françaises. Toutes nos réalisations proviennent de nos ateliers du Nord de la France.";
    const title2 = "SUR MESURE";
  const alt2 =
    "Photo en noir et blanc représentant les mains d'un menuisier utilisant un mètre ruban et un crayon pour mesurer une pièce de bois haut de gamme sur-mesure. Fabrication haut de gamme éternel sur-mesure et éthique Maison Charlet.";
  const photo3 =
    "/img/promesses/table_bois_chêne_massif_sur-mesure_MaisonCharlet.webp";
  const texte3 =
    " Nos meubles sur mesure sont conçus pour durer. Nous les garantissons 5 ans, mais surtout, parce que les goûts et les besoins évoluent, nous vous proposons de les modifier tout au long de leur vie. Que ce soit pour un déménagement, l'arrivée d'un nouveau membre dans la famille ou un changement de votre décoration, nous nous engageons à étudier avec vous toute modification de vos meubles Maison Charlet.";
    const title3 = "ETERNEL";
  const alt3 =
    "Fabrication table en bois sur-mesure à partir de bois massif dans atelier menuiserie. Plateau rectangulaire avec bords droits. Création haut de gamme éternel sur-mesure et éthique Maison Charlet.";

  return (
    <>
      <div className="promessesMainContainer">
        <div className="flexContainer">
          <div className="titlePromesse">Notre Promesse</div>
          <div className="dropdownsPromesseContainer">
            <DropDownCard
              photo={photo1}
              texte={texte1}
              title={title1}
              altText={alt1}
            />
            <DropDownCard
              photo={photo2}
              texte={texte2}
              title={title2}
              altText={alt2}
            />
            <DropDownCard
              photo={photo3}
              texte={texte3}
              title={title3}
              altText={alt3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Promesses;
