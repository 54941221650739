import React from 'react';
import Univers from './Univers';
import './UniversList.scss';



const UniversList = ({ listePhotosCampagne, listePhotosContemporain, listePhotosManoir, listePhotosPro }) => {
    return (
        <div className='universList'>
            <Univers universName={"campagne"}listePhotos={listePhotosCampagne}/>
            <Univers universName={"contemporain"} listePhotos={listePhotosContemporain} />
            <Univers universName={"manoir"} listePhotos={listePhotosManoir} />
            <Univers universName={"professionnel"} listePhotos={listePhotosPro} />
        </div>
    );
}

export default UniversList;