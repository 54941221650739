import React from "react";
import Carousel from "./Carousel";
import "./Univers.scss";

const UniversSansDropdown = ({ listePhotos, universName }) => {
  const universImagePath = `/img/univers/${universName}/main.webp`;
  const altTag = `Représentation de l'univers ${universName} avec des beaux meubles chaleureux et une ambiance cosy.`;

  return (
    <div className="universBackground">
      <div id={universName} className="universContainer">
        <div className="univers-mainImageContainer">
          <img className="imageUnivers" src={universImagePath} alt={altTag} />
          <div className="univers-dropCenterContainer">
            <div className="universName">{universName}</div>
          </div>
        </div>
        <div className="univers-photoContainer">
          <Carousel photos={listePhotos} />
        </div>
      </div>
    </div>
  );
};

export default UniversSansDropdown;
