import React, { useState, useEffect } from "react";
import "./CondenseDeRecherches.scss";
import CondenseItem from "./CondenseItem.js";
import { fetchPhotosCondense } from ".././Services/Database";

const CondenseDeRecherches = () => {
  const [photosData, setPhotosData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Faire défiler vers le haut lors du chargement initial de la page
  }, []);

  useEffect(() => {
    async function getPhotos() {
      let photos = await fetchPhotosCondense();
      setPhotosData(photos);
    }
    getPhotos();
  }, []);

  const selectTypeAndAddPath = (photoData, type) => {
    return photoData.filter(
      (photo) => (photo.typeDeMeuble === type) & (photo.isMainPhoto === false)
    );
  };

  const selectMainImage = (photoData, type) => {
    return photoData.find(
      (photo) => photo.typeDeMeuble === type && photo.isMainPhoto === true
    );
  };

  const listePhotosAmenagement = selectTypeAndAddPath(
    photosData,
    "amenagement"
  );
  const listePhotosCuisine = selectTypeAndAddPath(photosData, "cuisine");
  const listePhotosTables = selectTypeAndAddPath(photosData, "table");
  const listePhotosEtageres = selectTypeAndAddPath(photosData, "etagere");
  const listePhotosConsole = selectTypeAndAddPath(photosData, "console");
  const listePhotosAccessoires = selectTypeAndAddPath(
    photosData,
    "accessoires"
  );
  const listePhotosBanc = selectTypeAndAddPath(photosData, "banc");

  const mainImageTable = selectMainImage(photosData, "table");
  const mainImageCuisine = selectMainImage(photosData, "cuisine");
  const mainImageConsole = selectMainImage(photosData, "console");
  const mainImageEtagere = selectMainImage(photosData, "etagere");
  const mainImageAccessoires = selectMainImage(photosData, "accessoires");
  const mainImageAmenagement = selectMainImage(photosData, "amenagements");
  const mainImageBanc = selectMainImage(photosData, "banc");

  return (
    <>
      <div className="condenseBackgroundContainer">
        <div className="condenseContainer">
          <h1 className="mainTitle">CONDENSÉ DE RECHERCHES</h1>
          <h2 className="sloganCondense">
            Découvrez quelques unes de nos études et réalisations
          </h2>
          <div className="exempleBois">
            <img
              src="/img/CondenseDeRecherches/exempleDeBoisDivers.webp"
              alt="exemple de bois divers"
            ></img>
            <img
              src="/img/CondenseDeRecherches/Exemple etude condensé de recherche.webp"
              alt="exemple étude de réalisation de projets en bois"
            ></img>
          </div>

          <CondenseItem
            condenseItemName="TABLES"
            photos={listePhotosTables}
            mainPhoto={mainImageTable}
          />
          <CondenseItem
            condenseItemName="ÉTAGÈRES"
            photos={listePhotosEtageres}
            mainPhoto={mainImageEtagere}
          />
          <CondenseItem
            condenseItemName="CONSOLES & COMMODES"
            photos={listePhotosConsole}
            mainPhoto={mainImageConsole}
          />
          <CondenseItem
            condenseItemName="BANCS"
            photos={listePhotosBanc}
            mainPhoto={mainImageBanc}
          />
          <CondenseItem
            condenseItemName="ACCESSOIRES"
            photos={listePhotosAccessoires}
            mainPhoto={mainImageAccessoires}
          />
          <CondenseItem
            condenseItemName="AMÉNAGEMENTS"
            photos={listePhotosAmenagement}
            mainPhoto={mainImageAmenagement}
          />
          <CondenseItem
            condenseItemName="MEUBLES DE CUISINE"
            photos={listePhotosCuisine}
            mainPhoto={mainImageCuisine}
          />
        </div>
      </div>
    </>
  );
};

export default CondenseDeRecherches;
