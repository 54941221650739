// Footer.js

import React from "react";
import "./Footer.scss"; // Import du fichier SCSS pour appliquer les styles

const Footer = () => {
  return (
    <footer className="footer">
      <div className="adresseFooter">
        Maison Charlet <br></br>
        Marque déposée des établissements Albert Charlet 
        70 Rue Félix Faure, 59350 Saint-André-lez-Lille <br/>
        03 28 38 83 83 - 06 67 89 22 14
      </div>
      <div className="disclaimerFooter">*En soumettant ce message, vous acceptez que vos données personnelles <br></br> soient collectées et traitées par Maison Charlet pour traiter votre étude.</div>
    </footer>
  );
};

export default Footer;
