export const noLazyLoadPaths = [
    "/univers/campagne",
    "/univers/contemporain",
    "/univers/manoir",
    "/univers/professionnel",
    "/condense/accessoires",
    "/condense/amenagement",
    "/condense/banc",
    "/condense/console",
    "/condense/cuisine",
    "/condense/etageres",
    "/condense/table",
    "/nosrealisations-meubles-en-bois-sur-mesure"
  ];