import "./NosRealisations.scss";
import { useState, useEffect, React } from "react";
import CarouselDernieresRealisations from "./CarouselDernieresRealisations";
import { fetchPhotosNosRealisations } from "../Services/Database";
import { HashLink as Link } from "react-router-hash-link";
const NosRealisations = () => {
  const [listePhotos, setPhotosNosRealisationsData] = useState([]);

  useEffect(() => {
    async function getPhotos() {
      let listePhotosRealisation = await fetchPhotosNosRealisations();
      setPhotosNosRealisationsData(listePhotosRealisation);
    }
    getPhotos();
  }, []);

  return (
    <>
      <div className="flexContainer-no-margin">
        <Link className="titleNosRealisations" to="/nosrealisations-meubles-en-bois-sur-mesure">Nos Dernières Réalisations</Link>
        <CarouselDernieresRealisations
          photos={listePhotos}
        ></CarouselDernieresRealisations>
      </div>
    </>
  );
};

export default NosRealisations;
