import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_CHARLET_DATABASE_URL;
const supabaseKey = process.env.REACT_APP_CHARLET_DATABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const fetchPhotosUnivers = async () => {
  let { data: Photos, error } = await supabase
    .from("photosUnivers")
    .select(
      "id, univers, fileName, alt, ordreAffichage, imageMereId, photosUnivers(id, imageMereId,  fileName, alt) AS popIn"
    )
    .eq("isPopIn", false)
    .eq("active", true)
    .order("ordreAffichage");

  if (error) {
    console.error("Error fetching user data:", error.message);
    return;
  }
  return Photos.map(function (photo) {
    if (photo.photosUnivers.length > 0) {
      let fileName = photo.photosUnivers[0].fileName;
      photo.photosUnivers[0].fileName =
        `/img/univers/${photo.univers}/` + fileName;
    }

    return {
      ...photo,
      fileName: `/img/univers/${photo.univers}/` + photo.fileName,
    };
  });
};

const fetchPhotosNosRealisations = async () => {
  let { data: Photos, error } = await supabase
    .from("photosNosRealisationsWelcomePage")
    .select(
      "id, fileName, alt, ordreAffichage, imageMereId, filigrane, photosNosRealisationsWelcomePage(id, imageMereId,  fileName, alt) AS popIn"
    )
    .eq("isPopIn", false)
    .eq("active", true)
    .order("ordreAffichage");

  if (error) {
    console.error("Error fetching user data:", error.message);
    return;
  }

  return Photos.map(function (photo) {
    if (photo.photosNosRealisationsWelcomePage.length > 0) {
      let fileName = photo.photosNosRealisationsWelcomePage[0].fileName;
      photo.photosNosRealisationsWelcomePage[0].fileName =
        `/img/nosRealisations/` + fileName;
    }

    return {
      ...photo,
      fileName: `/img/nosRealisations/` + photo.fileName,
    };
  });
};

const fetchPhotosCondense = async () => {
  let { data: Photos, error } = await supabase
    .from("photosCondense")
    .select(
      "id, typeDeMeuble, fileName, alt, isMainPhoto, ordreAffichage, photosCondense(id, imageMereId,  fileName, alt) AS popIn"
    )
    .eq("isPopIn", false)
    .eq("active", true)
    .order("ordreAffichage");

  if (error) {
    console.error("Error fetching user data:", error.message);
    return;
  }

  return Photos.map(function (photo) {
    if (photo.photosCondense.length > 0) {
      let fileName = photo.photosCondense[0].fileName;
      photo.photosCondense[0].fileName =
        `/img/CondenseDeRecherches/` + fileName;
    }
    return {
      ...photo,
      fileName: `/img/CondenseDeRecherches/` + photo.fileName,
    };
  });
};

const createContact = async (contactName) => {
  let { data, error } = await supabase
    .from("Contacts")
    .insert([{ Name: contactName }])
    .select();
  if (error) {
  }
  return { data };
};

const updateContact = async (idToUpdate, columnName, propValue) => {
  let { error } = await supabase
    .from("Contacts")
    .update({ [columnName]: propValue })
    .eq("id", idToUpdate);
  if (error) {
  }
};

export {
  fetchPhotosUnivers,
  fetchPhotosCondense,
  fetchPhotosNosRealisations,
  createContact,
  updateContact,
};
