import React, { useState, useEffect } from "react";
import { fetchPhotosNosRealisations } from "../Services/Database";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import PopInNosRealisations from "./PopInNosRealisations.js";
import "./PageNosRealisations.scss";

const PageNosRealisations = () => {
  const [listePhotos, setPhotosNosRealisationsData] = useState([]);
  const [isHoveredIndex, setIsHoveredIndex] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Faire défiler vers le haut lors du chargement initial de la page
  }, []);

  useEffect(() => {
    async function getPhotos() {
      let listePhotosRealisation = await fetchPhotosNosRealisations();
      setPhotosNosRealisationsData(listePhotosRealisation);
    }
    getPhotos();
  }, []);

  const groupPhotos = (photos, itemsPerRow) => {
    const groupedPhotos = [];
    let startIndex = 0;

    itemsPerRow.forEach((count) => {
      groupedPhotos.push(photos.slice(startIndex, startIndex + count));
      startIndex += count;
    });

    return groupedPhotos;
  };

  const itemsPerRow = [2, 1, 2, 4, 2, 1, 3]; // Définit le nombre d'éléments par ligne
  
  const groupedPhotos = groupPhotos(listePhotos, itemsPerRow);

  const indexMaker = (index, rowIndex) => {return `${index}+${rowIndex}`}

  return (
    <>
      <div className="pageNosRealisationsContainer">
        <h1 className="mainTitleRealisations">NOS RÉALISATIONS</h1>
        <div className="parallaxPhotoContainer">
          <div className="photo-parallax-list">
            {groupedPhotos.map((row, rowIndex) => (
              <div key={rowIndex} className="row-container">
                {row.map((photo, index) => (
                  <ParallaxProvider>
                    <div key={indexMaker(index,rowIndex)} className={`photo-container-${index}-${rowIndex}`}>
                      <Parallax
                        speed={-index*20}
                        tagOuter="figure"
                        className="insideCadre"
                      >
                        <div
                          className={`cadrePopInNosRealisations ${
                            indexMaker(index,rowIndex) === isHoveredIndex ? "cadreHovered" : ""
                          } `}
                        >
                          <Parallax
                            translateY={["150px", "0px"]}
                            tagOuter="figure"
                            onMouseEnter={() => setIsHoveredIndex(`${index}+${rowIndex}`)}
                            onMouseLeave={() => setIsHoveredIndex(null)}
                          >
                            <PopInNosRealisations
                              photoElement={photo}
                              isShaking={false}
                            />
                          </Parallax>
                        </div>
                      </Parallax>
                    </div>
                  </ParallaxProvider>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNosRealisations;
