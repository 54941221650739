import { React, useEffect } from "react";
import logo from ".././img/logo-maison-charlet-blanc.webp";
import "./WelcomePage.scss";
import { Link } from "react-router-dom";
import BackgroundVideo from "./BackgroundVideo";
import Promesses from "./Promesses";
import NosRealisations from "./NosRealisations";
import AdnEtValeurs from "./Adn";

const WelcomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Faire défiler vers le haut lors du chargement initial de la page
  }, []);

  return (
    <>
      <div className="mainWelcomePageContainer">
        <div className="welcomePageContainer">
          <div className="innerContentWelcomePageContainer">
            <img src={logo} className="logo" alt="logo maison charlet" />
            <div className="separationBar"></div>
            <p className="slogan">
              Depuis 1948, nous construisons l'impossible <br /> A présent nous
              le meublons
            </p>
            <div className="realisationButtonContainer">
              <Link
                className="hoverCursor welcomeLinkCondense realisationsButton"
                to="/condensederecherche"
              >
                Découvrir
              </Link>
            </div>
          </div>
        </div>
        <BackgroundVideo
          isFixed={true}
          videoPath={"./welcomeVideo.mp4"}
        ></BackgroundVideo>
      </div>
      <NosRealisations />
      <Promesses />
      <AdnEtValeurs />
      <div className="mentionsLegales">
        <Link to="/mentionsLegales">MENTIONS LEGALES</Link>
      </div>
    </>
  );
};

export default WelcomePage;
