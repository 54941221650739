import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const PopInOverlay = ({ togglePopInOpen, photo, alt }) => {
  useEffect(() => {
    const portalElement = document.getElementById("portal-root");
    const overlayElement = document.createElement("div");

    const handleOutsideClick = (event) => {
      if (!overlayElement.contains(event.target)) {
        togglePopInOpen(false);
      }
    };

    portalElement.appendChild(overlayElement);

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      portalElement.removeChild(overlayElement);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [togglePopInOpen]);

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 9999,
  };

  return ReactDOM.createPortal(
    <div style={overlayStyle}>
      <div className="overlay">
        <div className="popin">
          <div className="popin-content">
            <img
              onClick={togglePopInOpen}
              className="popInImage"
              src={photo}
              alt={alt}
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
};

export default PopInOverlay;
