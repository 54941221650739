import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./PopIn.css";
import PopInOverlay from "./PopInOverlay";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {noLazyLoadPaths } from "../Common/constants"
import "react-lazy-load-image-component/src/effects/blur.css";

const PopIn = ({ photoElement, isShaking }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const togglePopin = () => {
    setIsOpen(!isOpen);
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  let photoPopIn = photoElement.fileName;
  let photoPopInAltTag = photoElement.alt;

  if (
    photoElement.photosUnivers !== undefined &&
    photoElement.photosUnivers.length > 0
  ) {
    photoPopIn = photoElement.photosUnivers[0].fileName;
    photoPopInAltTag = photoElement.photosUnivers[0].alt;
  } else if (
    photoElement.photosCondense !== undefined &&
    photoElement.photosCondense.length > 0
  ) {
    photoPopIn = photoElement.photosCondense[0].fileName;
    photoPopInAltTag = photoElement.photosCondense[0].alt;
  } 

  const useLazyLoading = !noLazyLoadPaths.includes(location.pathname);

  return (
    <>
      {useLazyLoading ? (
        <LazyLoadImage
          onClick={togglePopin}
          className={`photoElementPopIn hoverCursor ${
            isShaking || isLoaded ? "shake" : ""
          }`}
          src={photoElement.fileName}
          alt={photoElement.alt}
          effect="blur"
          afterLoad={handleImageLoad}
        />
      ) : (
        <img
          onClick={togglePopin}
          className="photoElementPopIn hoverCursor"
          src={photoElement.fileName}
          alt={photoElement.alt}
        />
      )}
      {isOpen && (
        <>
          <PopInOverlay
            togglePopInOpen={togglePopin}
            photo={photoPopIn}
            alt={photoPopInAltTag}
          />
        </>
      )}
    </>
  );
};

export default PopIn;
