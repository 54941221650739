import "./App.scss";
import React, { useState, useEffect } from "react";
import SlidableMenu from "./Components/SlidableMenu";
import Header from "./Components/Header";
import WelcomePage from "./Components/WelcomePage";
import UniversList from "./Components/UniversList";
import CondenseDeRecherches from "./Components/CondenseDeRecherches";
import { Route, Routes } from "react-router-dom";
import Questionnaire from "./Components/Questionnaire";
import { fetchPhotosUnivers, fetchPhotosCondense } from "./Services/Database";
import { MentionsLegales } from "./Components/MentionsLegales";
import CreezVotreUnivers from "./Components/CreezVotreUnivers";
import CondenseItem from "./Components/CondenseItem";
import { Helmet } from "react-helmet";
import UniversSansDropdown from "./Components/UniversSansDropdown";
import CookieConsent from "./Components/CookieConsent";
import PageNosRealisations from "./Components/PageNosRealisations";

function App() {
  const [photosUniversData, setPhotosUniversData] = useState([]);
  const [photosCondenseData, setPhotosCondenseData] = useState([]);

  useEffect(() => {
    async function getPhotos() {
      let photosUnivers = await fetchPhotosUnivers();
      setPhotosUniversData(photosUnivers);

      let photosCondense = await fetchPhotosCondense();
      setPhotosCondenseData(photosCondense);
    }
    getPhotos();
  }, []);

  const selectItemsAndUpdateFilePath = (photoData, type) => {
    return photoData.filter(
      (photo) => (photo.typeDeMeuble === type) & (photo.isMainPhoto === false)
    );
  };

  const selectUniversAndUpdateFilePath = (photoData, universName) => {
    return photoData.filter((photo) => photo.univers === universName);
  };

  const selectMainImage = (photoData, type) => {
    return photoData.find(
      (photo) => photo.typeDeMeuble === type && photo.isMainPhoto === true
    );
  };

  const photoUniversCampagne = selectUniversAndUpdateFilePath(
    photosUniversData,
    "campagne"
  );
  const photoUniversContemporain = selectUniversAndUpdateFilePath(
    photosUniversData,
    "contemporain"
  );
  const photoUniversManoir = selectUniversAndUpdateFilePath(
    photosUniversData,
    "manoir"
  );
  const photoUniversPro = selectUniversAndUpdateFilePath(
    photosUniversData,
    "professionnel"
  );

  const listePhotosAmenagement = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "amenagement"
  );
  const listePhotosCuisine = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "cuisine"
  );
  const listePhotosTables = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "table"
  );
  const listePhotosEtageres = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "etagere"
  );
  const listePhotosConsole = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "console"
  );
  const listePhotosAccessoires = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "accessoires"
  );
  const listePhotosBanc = selectItemsAndUpdateFilePath(
    photosCondenseData,
    "banc"
  );

  const mainImageTable = selectMainImage(photosCondenseData, "table");
  const mainImageCuisine = selectMainImage(photosCondenseData, "cuisine");
  const mainImageConsole = selectMainImage(photosCondenseData, "console");
  const mainImageEtagere = selectMainImage(photosCondenseData, "etagere");
  const mainImageAccessoires = selectMainImage(
    photosCondenseData,
    "accessoires"
  );
  const mainImageAmenagement = selectMainImage(
    photosCondenseData,
    "amenagement"
  );
  const mainImageBanc = selectMainImage(photosCondenseData, "banc");

  return (
    <div className="App">
      <CookieConsent></CookieConsent>
      <Header />
      <SlidableMenu />
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <Helmet>
                <link rel="canonical" href="https://maisoncharlet.fr" />
              </Helmet>
              <WelcomePage />
            </>
          }
        />
        <Route
          path="/univers"
          element={
            <UniversList
              listePhotosCampagne={photoUniversCampagne}
              listePhotosContemporain={photoUniversContemporain}
              listePhotosManoir={photoUniversManoir}
              listePhotosPro={photoUniversPro}
            />
          }
        />
        <Route
          path="/univers/campagne"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/univers/campagne"
                />
              </Helmet>
              <UniversSansDropdown
                universName={"Campagne"}
                listePhotos={photoUniversCampagne}
              />
            </>
          }
        />
        <Route
          path="/univers/contemporain"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/univers/contemporain"
                />
              </Helmet>
              <UniversSansDropdown
                universName={"Contemporain"}
                listePhotos={photoUniversContemporain}
              />
            </>
          }
        />
        <Route
          path="/univers/manoir"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/univers/manoir"
                />
              </Helmet>
              <UniversSansDropdown
                universName={"Manoir"}
                listePhotos={photoUniversManoir}
              />
            </>
          }
        />
        <Route
          path="/univers/professionnel"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/univers/professionnel"
                />
              </Helmet>
              <UniversSansDropdown
                universName={"professionnel"}
                listePhotos={photoUniversPro}
              />
            </>
          }
        />
        <Route path="/condensederecherche" element={<CondenseDeRecherches />} />
        <Route
          path="/condensederecherche/amenagements"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/amenagements"
                />
              </Helmet>
              <CondenseItem
                condenseItemName="AMÉNAGEMENTS"
                photos={listePhotosAmenagement}
                mainPhoto={mainImageAmenagement}
              />
            </>
          }
        />
        <Route
          path="/condensederecherche/cuisines"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/cuisines"
                />
              </Helmet>
              <CondenseItem
                condenseItemName="CUISINE"
                photos={listePhotosCuisine}
                mainPhoto={mainImageCuisine}
              />
            </>
          }
        />
        <Route
          path="/condensederecherche/tables"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/tables"
                />
              </Helmet>
              <CondenseItem
                condenseItemName="TABLES"
                photos={listePhotosTables}
                mainPhoto={mainImageTable}
              />
            </>
          }
        />
        <Route
          path="/condensederecherche/etageres"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/etageres"
                />
              </Helmet>

              <CondenseItem
                condenseItemName="ÉTAGÈRES"
                photos={listePhotosEtageres}
                mainPhoto={mainImageEtagere}
              />
            </>
          }
        />
        <Route
          path="/condensederecherche/consoles"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/consoles"
                />
              </Helmet>
              <CondenseItem
                condenseItemName="CONSOLES"
                photos={listePhotosConsole}
                mainPhoto={mainImageConsole}
              />
            </>
          }
        />
        <Route
          path="/condensederecherche/accessoires"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/accessoires"
                />
              </Helmet>
              <CondenseItem
                condenseItemName="ACCESSOIRES"
                photos={listePhotosAccessoires}
                mainPhoto={mainImageAccessoires}
              />
            </>
          }
        />
        <Route
          path="/condensederecherche/bancs"
          element={
            <>
              <Helmet>
                <link
                  rel="canonical"
                  href="https://maisoncharlet.fr/condensederecherche/bancs"
                />
              </Helmet>
              <CondenseItem
                condenseItemName="BANCS"
                photos={listePhotosBanc}
                mainPhoto={mainImageBanc}
              />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <Questionnaire
              questions={[
                {
                  text: "Afin de continuer votre expérience avec Maison Charlet, Pouvez-vous nous indiquer votre nom et prénom ?",
                  type: "text",
                  dataName: "Name",
                },
                {
                  text: "Quel est votre numéro de téléphone ?",
                  type: "phone",
                  dataName: "Phone",
                },
                {
                  text: "Quelle est votre adresse email  ?",
                  type: "email",
                  dataName: "Email",
                },
                {
                  text: "Quel type de projet souhaitez vous réaliser ?",
                  type: "text",
                  dataName: "ProjectType",
                },
                {
                  text: "Quel est votre budget approximatif pour ce projet  ?",
                  type: "text",
                  dataName: "Budget",
                },
                {
                  text: "Quelle est la date souhaitée de réalisation ?",
                  type: "text",
                  dataName: "Date",
                },
              ]}
            />
          }
        />
        <Route
          path="/creezVotreUnivers-meubles-en-bois-fait-main"
          element={<CreezVotreUnivers />}
        />
        <Route path="/mentionsLegales" element={<MentionsLegales />} />
        <Route
          path="/nosrealisations-meubles-en-bois-sur-mesure"
          element={<PageNosRealisations />}
        />
      </Routes>
    </div>
  );
}

export default App;
