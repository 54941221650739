import { React, useEffect, useState } from "react";
import "./CreezVotreUnivers.scss";

const CreezVotreUnivers = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Fonction pour détecter la taille de l'écran
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 825); // Vous pouvez ajuster la taille ici selon vos besoins
    };

    // Ajoute un écouteur d'événement pour la redimension de la fenêtre
    window.addEventListener("resize", handleResize);

    // Appel initial pour définir l'état initial
    handleResize();

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Le tableau vide assure que cela ne s'exécute qu'une seule fois après le rendu initial

  useEffect(() => {
    window.scrollTo(0, 0); // Faire défiler vers le haut lors du chargement initial de la page
  }, []);

  return (
    <div className="creez-votre-univers">
      <div className="creezVotreUnivers-title">Créez Votre Univers</div>
      <div className="creezVotreUniversAvantApresContainer">
        <img
          src="img/creezVotreUnivers/Photo_Avant_création_cuisine_MaisonCharlet.webp"
          alt="Représentation d'un espace cuisine vide avant la création sur-mesure par Maison Charlet."
        ></img>
        <img
          src="img/creezVotreUnivers/Dessin_créez_votre_univers_MaisonCharlet.webp"
          alt="Dessin de l’objectif défini par le client, avec propositions de design indiquées. Réalisation graphique par Maison Charlet"
        ></img>
        <img
          src="img/creezVotreUnivers/Photo_après_création_MaisonCharlet.webp"
          alt="Représentantion de l’aménagement d’une cuisine après créations sur-mesure et interventions de Maison Charlet."
        ></img>
      </div>
      {isSmallScreen ? (
        <div className="detailCreezVotreUniversContainer">
          <img
            src="img/creezVotreUnivers/1_rencontre.webp"
            alt="Nous venons découvrir votre intérieur et vous présenter notre savoir-faire Maison Charlet"
          ></img>
          <img
            src="img/creezVotreUnivers/2_étude.webp"
            alt="Nous recherchons les formes, matières, couleurs et dimensions idéales pour rendre votre projet unique"
          ></img>
          <img
            src="img/creezVotreUnivers/3_réalisation_installation.webp"
            alt="Nous fabriquons à la main votre meuble dans nos ateliers de Saint-André-lez-Lille et venons l'installer chez vous"
          ></img>
        </div>
      ) : (
        <div className="detailCreezVotreUniversContainer">
          <img
            src="img/creezVotreUnivers/Maquette Créez votre univers suite.webp "
            alt="Nous venons découvrir votre intérieur et vous présenter notre savoir-faire Maison Charlet"
          ></img>
        </div>
      )}
    </div>
  );
};

export default CreezVotreUnivers;
