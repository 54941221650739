import React from "react";
import "./Adn.scss";
import BackgroundVideo from "./BackgroundVideo";
const AdnEtValeurs = ({ isFixed }) => {
  return (
    <>
      <div id="adn" className="adnContainer">
        <div className="adnTitle">Notre ADN</div>
        <div className="adnContent">
          Depuis 1948, les Etablissements{" "}
          <a href="https://www.charlet-bois.com/">Albert Charlet</a> réalisent
          des ouvrages en bois de grande envergure pour les institutions les
          plus prestigieuses en France comme à l’étranger: fabrication
          d’hôpitaux, de ponts, de musées, ou encore d’universités.
          <br /> <br />
          Forts de notre expérience, nous avons créé Maison Charlet, une marque
          de mobilier sur mesure afin d’offrir notre savoir-faire aux
          particuliers.
          <br /> <br />
          L’anatomie d’un meuble Maison Charlet: l'exigence d'une qualité de
          conception identique à celle mise en œuvre dans la fabrication de nos
          ouvrages les plus illustres.
          <br /> <br />
        </div>
        <BackgroundVideo
          isFixed={isFixed}
          videoPath={"./adnVideo.mp4"}
        ></BackgroundVideo>
      </div>
    </>
  );
};

export default AdnEtValeurs;
