import React, { useState, useEffect } from "react";
import Carousel from "./Carousel";
import "./Univers.scss";

const Univers = ({ listePhotos, universName }) => {
  const universImagePath = `/img/univers/${universName}/main.webp`;
  const altTag = `Représentation de l'univers ${universName} avec des beaux meubles chaleureux et une ambiance cosy.`;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Faire défiler vers le haut lors du chargement initial de la page
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      // inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      var element = document.getElementById(`zoomRef-${universName}`);
      if (element) {
        var margin = 200; // Adjust this margin as needed
        var offsetTop = element.getBoundingClientRect().top + window.pageYOffset - margin;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    }
  };

  const style = {
    backgroundImage: 'url("/img/chevronWhite.webp")',
  };

  return (
    <>
      <div className="universBackground">
        <div id={universName} className="universContainer">
          <div className="univers-mainImageContainer" onClick={toggleDropdown}>
            <img className="imageUnivers" src={universImagePath} alt={altTag} />
            <div className="univers-dropCenterContainer">
              <div className="universName">{universName}</div>
              <div className="univers-dropdown-button-container">
                <button
                  className={
                    isOpen ? "dropdown-button rotate" : "dropdown-button"
                  }
                  aria-label="Ouvrir le menu défilant des différents exemples de meubles de l'univers concerné"
                  style={style}
                />
              </div>
            </div>
          </div>
          <div id={`zoomRef-${universName}`} className="univers-dropContentContainer">
            <div
              className={`univers-dropdown-container  ${
                isOpen ? "open" : "hidden"
              }`}
            >
              {
                <div
                  className={`univers-dropdown-content ${
                    isOpen ? "open" : "hidden"
                  }`}
                >
                  <div className="univers-photoContainer">
                    <Carousel photos={listePhotos} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Univers;