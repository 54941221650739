import React from 'react';
import "./BackgroundVideo.scss";

const BackgroundVideo = ({videoPath, isFixed}) => {
    return (
        <div className="background-video">
            <video autoPlay loop muted playsInline poster={"./BackgroundVideo.webp"} className="background-video">
                <source src={videoPath} type="video/mp4" />
                {/* Ajoutez d'autres sources si nécessaire (WebM, Ogg, etc.) */}
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default BackgroundVideo;
