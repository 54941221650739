import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./PopIn.css";
import "./PopInNosRealisations.scss";
import PopInOverlay from "./PopInOverlay";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { noLazyLoadPaths } from "../Common/constants";
import "react-lazy-load-image-component/src/effects/blur.css";

const PopInNosRealisations = ({ photoElement, isShaking }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const togglePopin = () => {
    setIsOpen(!isOpen);
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  let photoPopIn = photoElement.fileName;
  let photoPopInAltTag = photoElement.alt;

  if (
    photoElement.photosNosRealisationsWelcomePage !== undefined &&
    photoElement.photosNosRealisationsWelcomePage.length > 0
  ) {
    photoPopIn = photoElement.photosNosRealisationsWelcomePage[0].fileName;
    photoPopInAltTag = photoElement.photosNosRealisationsWelcomePage[0].alt;
  }

  const useLazyLoading = !noLazyLoadPaths.includes(location.pathname);

  return (
    <>
      {useLazyLoading ? (
        <>
          <LazyLoadImage
            onClick={togglePopin}
            className={`photoElementPopInNosRealisations hoverCursor ${
              isShaking || isLoaded ? "shake" : ""
            }`}
            src={photoElement.fileName}
            alt={photoElement.alt}
            effect="blur"
            afterLoad={handleImageLoad}
          />
        </>
      ) : (
        <img
          onClick={togglePopin}
          className="photoElementPopInNosRealisations hoverCursor"
          src={photoElement.fileName}
          alt={photoElement.alt}
        />
      )}
      {isOpen && (
        <>
          <PopInOverlay
            togglePopInOpen={togglePopin}
            photo={photoPopIn}
            alt={photoPopInAltTag}
          />
        </>
      )}
    </>
  );
};

export default PopInNosRealisations;
